<template>
<!--腾讯云点播视频-->
    <div class="video" >
      {{utils_fileId}}
      <video
             v-if="newFileId"
             :id="tcPlayerId"
             class="tc-video-container"
             playsinline
             webkit-playinline
             x5-playinline>
      </video>
    </div>
</template>

<script>

export default {
  name: 'videos',
  props:['utils_fileId'],
  data () {
    return {
      tcPlayerId: 'tcPlayer' + Date.now(),
      player: null,
      videoFileId: '',
      videoAppid: '',
      newFileId:'',
    }
  },
  created() {
    this.newFileId = this.$route.query.fileId
  },
  mounted() {
      this.getFileID()
  },
  watch:{
  },
  methods: {
    // 初始化腾讯云播放器
    getFileID(){
      let self = this
      setTimeout(() => {
        self.videoFileId = self.newFileId
        self.videoAppid = '1252497858'
        self.$nextTick(() => {
          const player = {
            fileID: self.videoFileId,
            appID: self.videoAppid,
            autoplay: true,
            plugins: {
              ProgressMarker: true
            }
          }
          self.player = window.TCPlayer(self.tcPlayerId, player)
        })
      }, 100)
    },
  },
  beforeDestroy () {
    this.player.dispose()
  }
}
</script>
<style lang="scss" scoped>
.video{
  align-items: center;
  video{
  }
  ::v-deep .video-js{
    width: auto;
    height: 200px;
  }
}
</style>
